import React from 'react';

// components
import SEO from '../components/seo';
import Nav from '../components/Nav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Container from '../components/styled/Container';
import Schedule from '../components/sections/Schedule';

class Sponsorship extends React.Component {
  render() {
    return (
      <Container>
        <SEO title="Schedule" />
        <Nav selected="/schedule" />
        <Header />
        <Schedule />
        <Footer />
      </Container>
    );
  }
}

export default Sponsorship;
