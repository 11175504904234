import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// styled
import Section from '../styled/Section';
import Superscript from '../styled/Superscript';
import Wrapper from '../styled/Wrapper';
import Row from '../styled/Row';

// assets
import adam from '../../assets/speakers2019/adam.jpg';
import carolina from '../../assets/speakers2019/carolina.jpg';
import chris from '../../assets/speakers2019/chris.jpg';
import clare from '../../assets/speakers2019/clare.jpg';
import colin from '../../assets/speakers2019/colin.png';
import isaac from '../../assets/speakers2019/isaac.jpg';
import james from '../../assets/speakers2019/james.jpg';
import jason from '../../assets/speakers2019/jason.jpg';
import josh from '../../assets/speakers2019/josh.jpg';
import michael from '../../assets/speakers2019/michael.jpg';
import nancy from '../../assets/speakers2019/nancy.jpg';
import ryan from '../../assets/speakers2019/ryan.jpg';

import scheduleDetail from '../../assets/schedule-detail.svg';

const Schedule = () => (
  <Section squareDetail="left" dotDetail="right" noPaddingTop>
    <Wrapper>
      <Row>
        <Superscript center>Schedule for September 2019</Superscript>
      </Row>

      <DateBox>12 September</DateBox>

      <Event>
        <EventWrapper>
          <EventInner lighten>
            <Time>8:00 am</Time>
            <p>Registration and Breakfast</p>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner lighten>
            <Time>9:15 am</Time>
            <p>Opening remarks 👋</p>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner to="/speakers#james">
            <Time>9:30 am</Time>
            <p>Thinking in tests</p>
            <Speaker>
              <img src={james} />
              <h3>James Shore</h3>
              <h4>&nbsp;</h4>
            </Speaker>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner to="/speakers#adam">
            <Time>10:05 am</Time>
            <p>Scaling R&D to thousands of people without dedicated QA</p>
            <Speaker>
              <img src={adam} />
              <h3>Adam Archer</h3>
              <h4>Shopify</h4>
            </Speaker>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner lighten>
            <Time>10:40 am</Time>
            <p>Break</p>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner to="/speakers#josh">
            <Time>11:10 am</Time>
            <p>Old solutions to new testing problems</p>
            <Speaker>
              <img src={josh} />
              <h3>Josh Justice</h3>
              <h4>Big Nerd Ranch</h4>
            </Speaker>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner to="/speakers#carolina">
            <Time>11:45 am</Time>
            <p>Robust tests for unconventional environments</p>
            <Speaker>
              <img src={carolina} />
              <h3>Carolina Pinzon</h3>
              <h4>Dapper Labs</h4>
            </Speaker>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner lighten>
            <Time>12:20 pm</Time>
            <p>Lunch</p>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner to="/speakers#ryan">
            <Time>1:20 pm</Time>
            <p>
              This clever workshop will convert your team into TDD enthusiasts
            </p>
            <Speaker>
              <img src={ryan} />
              <h3>Ryan Marsh</h3>
              <h4>thestack.io</h4>
            </Speaker>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner to="/speakers#isaac">
            <Time>1:55 pm - ⚡️</Time>
            <div>
              <p>99% is Not Enough - Full Test Coverage And Why It's Awesome</p>
              <Speaker>
                <img src={isaac} />
                <h3>Isaac Z. Schlueter</h3>
                <h4>npm</h4>
              </Speaker>
            </div>
            <hr />
            <Link to="/speakers#clare">
              <p>Pros and cons of UI testing tools</p>
              <Speaker>
                <img src={clare} />
                <h3>Clare So</h3>
                <h4>OTTO Motors</h4>
              </Speaker>
            </Link>
            <hr />
            <Link to="/speakers#colin">
              <p>tests > types</p>
              <Speaker>
                <img src={colin} />
                <h3>Colin Ihrig</h3>
                <h4>Joyent</h4>
              </Speaker>
            </Link>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner to="/speakers#nancy">
            <Time>2:40 pm</Time>
            <p>Integration Testing with Cypress</p>
            <Speaker>
              <img src={nancy} />
              <h3>Nancy Du</h3>
              <h4>Rangle.io</h4>
            </Speaker>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner lighten>
            <Time>3:15 pm</Time>
            <p>Break</p>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner to="/speakers#jason">
            <Time>3:30 pm</Time>
            <p>
              Test Flakiness - methods for identifying and dealing with flaky
              tests
            </p>
            <Speaker>
              <img src={jason} />
              <h3>Jason Palmer</h3>
              <h4>Spotify</h4>
            </Speaker>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner to="/speakers#michael">
            <Time>4:05 pm</Time>
            <p>Component-Driven Development & Visual Test Coverage</p>
            <Speaker>
              <img src={michael} />
              <h3>Michael Shilman</h3>
              <h4>Chroma, Storybook</h4>
            </Speaker>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event>
        <EventWrapper>
          <EventInner lighten>
            <Time>4:40 pm</Time>
            <p>Closing remarks 👏</p>
          </EventInner>
        </EventWrapper>
      </Event>

      <Event last>
        <EventWrapper>
          <EventInner lighten to="/location#afterparty">
            <Time>6:00 pm</Time>
            <p>After-party 🤘</p>
          </EventInner>
        </EventWrapper>
      </Event>
      <ShadowDetail />
    </Wrapper>
  </Section>
);

const ShadowDetail = styled.div`
  width: 100%;
  height: 0px;
  box-shadow: 0 0 20px 30px #131313;
  z-index: 99;
  position: relative;
`;

const DateBox = styled.div`
  border: 3px solid white;
  text-align: center;
  margin: 0 auto;
  align-self: center;
  display: flex;
  width: 190px;
  padding: 12px 10px 8px;
  justify-content: center;
  margin-bottom: 24px;
  background-color: #131313;
  z-index: 9999;
  position: relative;
  font-family: Orbitron;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 16px;
  ::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 30px;
    background-color: white;
    left: calc(50% - 3px);
    bottom: -30px;
  }
`;

const EventWrapper = styled.div``;

const Event = styled.div`
  width: 50%;
  display: block;
  position: relative;
  border-right: 3px solid white;
  clear: both;
  text-decoration: none;
  ${props => (props.last ? 'padding-bottom: 48px;' : null)}
  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: white;
    position: absolute;
    right: 0;
    top: 50%;
    ${props => (props.last ? 'top: calc(50% - 24px);' : null)}
  }
  ::before {
    content: '';
    display: block;
    width: 19px;
    height: 19px;
    border: 3px solid white;
    position: absolute;
    right: -11px;
    top: calc(50% - 8px);
    background-color: #131313;
    z-index: 99;
    ${props => (props.last ? 'top: calc(50% - 32px);' : null)}
    @media (max-width: 820px) {
      display: none;
    }
  }
  :nth-child(odd) {
    float: right;
    box-shadow: -3px 0px 0px 0px white;
    border-right: none;
    @media (max-width: 777px) {
      margin-top: -24px;
      padding-top: 24px;
    }
    ::after {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      dbackground-color: white;
      position: absolute;
      left: 0;
      top: 50%;
    }
    ::before {
      content: '';
      display: block;
      width: 19px;
      height: 19px;
      border: 3px solid white;
      position: absolute;
      left: -11px;
      top: calc(50% - 8px);
      background-color: #131313;
      z-index: 99;
      @media (max-width: 820px) {
        display: none;
      }
    }
    > div {
      float: right;
    }
  }
  :hover {
    > div > a {
      border: 3px solid #fff300;
    }

    ::before {
      border-color: #fff300;
      background-color: #fff300;
    }
    ::after {
      background-color: #fff300;
    }
  }
`;

const EventInner = styled(Link)`
  display: block;
  text-decoration: none;
  position: relative;
  border: 3px solid white;
  width: 350px;
  padding: 32px;
  clear: both;
  background-color: #131313;
  z-index: 99;
  font-size: 16px;
  line-height: 24px;
  background-image: url(${scheduleDetail});
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) calc(0% + 10px);
  a {
    text-decoration: none;
  }
  ${props => (!props.to ? 'pointer-events: none;' : null)}
  ${props => (props.lighten ? `background-color: #333;` : null)}
  p {
    margin-bottom: 0;
  }
  hr {
    margin-top: 24px;
    margin-bottom: 16px;
    background-color: white;
    height: 3px;
    opacity: 0.25;
  }
  @media (max-width: 777px) {
    margin-bottom: 24px;
  }
`;

const Time = styled.div`
  text-transfor: uppercase;
  font-family: Orbitron;
  font-size: 20px;
  margin-bottom: 12px;
`;

const Speaker = styled.div`
  margin-top: 24px;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    float: left;
    margin-right: 24px;
  }
  h3 {
    font-family: Orbitron;
    color: #fff300;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
  }
  h4 {
    margin-bottom: 0;
    font-family: Orbitron;
    font-weight: 300;
  }
`;

export default Schedule;
